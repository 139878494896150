@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}


/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  margin: 0;
  padding: 0;
}


.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 40px;
  align-items: flex-end;
  justify-content: flex-end;
}

.bookingPanel {
  background-color: white !important;
}

.bookingTitle {
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}